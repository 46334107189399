import { FunctionComponent } from "react";

const LandingVersionFinal: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-base-white overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-[13px] box-border min-h-[6957px] tracking-[normal]">
      <header className="self-stretch h-[97px] bg-base-white [backdrop-filter:blur(64px)] flex flex-row items-center justify-center py-main-system-20 px-[74px] box-border top-[0] z-[99] sticky max-w-full mq750:pl-[37px] mq750:pr-[37px] mq750:box-border">
        <img className="h-10 w-40 relative hidden z-[1]" alt="" />
        <div className="self-stretch flex-1 flex flex-row items-center justify-between max-w-full gap-[20px]">
          <img
            className="h-[52px] w-[259px] relative"
            loading="lazy"
            alt=""
            src="/vector.svg"
          />
          <nav className="m-0 w-[247px] flex flex-row items-center justify-start gap-[32px] text-left text-base text-darkslateblue-200 font-barlow mq750:hidden">
            <div className="rounded-roundness-inside-m overflow-hidden flex flex-row items-center justify-center gap-[6px]">
              <img
                className="h-5 w-5 relative overflow-hidden shrink-0 hidden"
                alt=""
              />
              <div className="relative tracking-[0.1px] leading-[22px] font-semibold inline-block min-w-[42px]">
                Home
              </div>
              <img
                className="h-5 w-5 relative overflow-hidden shrink-0 hidden"
                alt=""
              />
            </div>
            <div className="rounded-roundness-inside-m overflow-hidden flex flex-row items-center justify-center gap-[6px]">
              <img
                className="h-5 w-5 relative overflow-hidden shrink-0 hidden"
                alt=""
              />
              <div className="relative tracking-[0.1px] leading-[22px] font-semibold inline-block min-w-[64px] whitespace-nowrap">
                About us
              </div>
              <img
                className="h-5 w-5 relative overflow-hidden shrink-0 hidden"
                alt=""
              />
            </div>
            <div className="self-stretch hidden flex-col items-end justify-start py-0 pr-0 pl-[34px] text-right text-mini text-text-icons-base-main font-text-l-regular">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <img
                  className="h-5 w-5 relative overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <div className="self-stretch relative tracking-[0.1px] leading-[22px] font-semibold whitespace-nowrap">
                  Resources
                </div>
                <img
                  className="h-5 w-5 relative overflow-hidden shrink-0"
                  alt=""
                />
              </div>
            </div>
            <div className="rounded-roundness-inside-m overflow-hidden flex flex-row items-center justify-center gap-[6px]">
              <img
                className="h-5 w-5 relative overflow-hidden shrink-0 hidden"
                alt=""
              />
              <div className="relative tracking-[0.1px] leading-[22px] font-semibold inline-block min-w-[77px] whitespace-nowrap">
                Contact us
              </div>
              <img
                className="h-5 w-5 relative overflow-hidden shrink-0 hidden"
                alt=""
              />
            </div>
          </nav>
          <div className="flex flex-row items-center justify-center py-[7px] px-0">
            <button className="cursor-pointer [border:none] py-4 px-5 bg-mediumseagreen-100 rounded-31xl flex flex-row items-center justify-center whitespace-nowrap hover:bg-mediumseagreen-200">
              <b className="relative text-base leading-[11px] font-urbanist text-darkslateblue-200 text-left">
                Get Started Today
              </b>
            </button>
            <div className="h-[42px] w-[42px] rounded-31xl bg-mediumseagreen-100 flex flex-row items-center justify-start p-2.5 box-border">
              <img
                className="h-[22px] w-[22px] relative overflow-hidden shrink-0 object-cover"
                loading="lazy"
                alt=""
                src="/icon--arrow-top-right@2x.png"
              />
            </div>
          </div>
        </div>
      </header>
      <section className="mr-[-2px] mb-20 self-stretch bg-whitesmoke overflow-hidden flex flex-row items-end justify-start pt-24 px-[111px] pb-[98px] box-border gap-[52px] max-w-full text-left text-[64px] text-darkslateblue-200 font-urbanist mq750:gap-[52px_26px] mq750:pt-[62px] mq750:px-[27px] mq750:pb-16 mq750:box-border mq1275:flex-wrap mq1275:justify-center mq1275:pl-[55px] mq1275:pr-[55px] mq1275:box-border">
        <div className="w-[598px] flex flex-col items-start justify-start gap-[24px] min-w-[598px] max-w-full mq1100:min-w-full mq1275:flex-1">
          <h1 className="m-0 self-stretch relative text-inherit leading-[100%] font-bold font-inherit mq750:text-[51px] mq750:leading-[51px] mq450:text-[38px] mq450:leading-[38px]">
            Create Your Own Island on the Blockchain
          </h1>
          <div className="self-stretch relative text-xl leading-[24px] font-medium mq450:text-base mq450:leading-[19px]">
            Our platform offers unprecedented accesibility, ensuring property
            transactions are open to all. Embrace the security of our state of
            the art technology, ensuring every step is safe guarded. Say
            farewell to cumbersobe paperwork, as our digital solution provides
            immutable records for easy management.
          </div>
        </div>
        <div className="h-[376px] w-[459px] flex flex-col items-start justify-end pt-0 px-0 pb-1 box-border min-w-[459px] max-w-full mq750:min-w-full mq1275:flex-1">
          <img
            className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full mq1275:self-stretch mq1275:w-auto"
            loading="lazy"
            alt=""
            src="/-03.svg"
          />
        </div>
      </section>
      <section className="self-stretch flex flex-row items-start justify-center pt-0 pb-20 pr-5 pl-6 box-border max-w-full text-left text-21xl text-darkslateblue-200 font-urbanist mq450:pb-[52px] mq450:box-border">
        <div className="w-[1220px] flex flex-row flex-wrap items-start justify-start gap-[22px] max-w-full">
          <h1 className="m-0 w-[392px] relative text-inherit leading-[100%] font-bold font-inherit inline-block shrink-0 max-w-full mq750:text-13xl mq750:leading-[32px] mq450:text-5xl mq450:leading-[24px]">
            Welcome
          </h1>
          <h3 className="m-0 flex-1 relative text-5xl leading-[150%] font-normal font-barlow inline-block min-w-[524px] max-w-full mq750:min-w-full mq450:text-lgi mq450:leading-[29px]">
            Welcome to Smart Block Island, your gateway to innovative and
            seamless real estate tokenization solutions. At Smart Block Island,
            we harness the power of blockchain technology to revolutionize the
            way you approach real estate investments. Whether you’re seasoned
            developer or a first-time investor, our platform offers
            transparency, accessibility, and security, unlocking new
            possibilities for growth in the dynamic world of tokenized real
            estate. Join us on exciting journey where innovation meets
            opportunity, and let’s shape the future of real estate together.
          </h3>
        </div>
      </section>
      <section className="mr-[-2px] self-stretch bg-mediumseagreen-100 flex flex-col items-center justify-center py-20 px-5 box-border max-w-full text-left text-21xl text-darkslateblue-200 font-urbanist mq750:pt-[52px] mq750:pb-[52px] mq750:box-border">
        <div className="w-[1220px] flex flex-col items-start justify-start gap-[60px] max-w-full mq750:gap-[30px_60px]">
          <h1 className="m-0 w-[600px] relative text-inherit leading-[100%] font-bold font-inherit inline-block max-w-full mq750:text-13xl mq750:leading-[32px] mq450:text-5xl mq450:leading-[24px]">
            Your Island on the Blockchain
          </h1>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[24px_18px] min-h-[444px] max-w-full text-xl font-barlow">
            <div className="w-[600px] flex flex-col items-start justify-start gap-[24px] max-w-full">
              <img
                className="w-[60px] h-[60px] relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/cryptocurrency04.svg"
              />
              <div className="self-stretch relative leading-[140%] mq450:text-base mq450:leading-[22px]">
                <b>Smart Contracts</b>
                <span className="text-dimgray">
                  {" "}
                  in the tokenization of assets are self-executing digital
                  agreements that automatically enforce the terms and conditions
                  of asset ownership and transfer, enabling efficient and
                  transparent management of tokenized assets on a blockchain.
                </span>
              </div>
            </div>
            <div className="w-[600px] flex flex-col items-start justify-start gap-[24px] max-w-full">
              <img
                className="w-[60px] h-[60px] relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/server06.svg"
              />
              <div className="self-stretch relative leading-[140%] mq450:text-base mq450:leading-[22px]">
                <b>The integration</b>
                <span className="text-dimgray">
                  , on the blockchain refers to the process of incorporating
                  data, applications, or services into a decentralized and
                  secure blockchain network, enabling enhanced transparency,
                  immutability, and trust in various systems and processes.
                </span>
              </div>
            </div>
            <div className="w-[600px] flex flex-col items-start justify-start gap-[24px] max-w-full">
              <img
                className="w-[60px] h-[60px] relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/passcodelock.svg"
              />
              <div className="self-stretch relative leading-[140%] mq450:text-base mq450:leading-[22px]">
                <b>Security</b>
                <span className="text-dimgray">
                  , in the tokenization of assets is ensured through the use of
                  cryptographic protocols and blockchain technology, providing
                  tamperresistant ownership records, transparents transaction
                  history, and decentralized control to protect against
                  unauthorized access and fraud.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mr-[-2px] self-stretch h-[408px] bg-darkslateblue-200 overflow-hidden shrink-0 flex flex-row items-end justify-start pt-[129px] pb-[101px] pr-0.5 pl-[102px] box-border gap-[90px] max-w-full text-left text-24xl text-base-white font-urbanist mq750:gap-[90px_45px] mq750:pl-[51px] mq750:box-border mq1100:pt-[84px] mq1100:pb-[66px] mq1100:box-border mq450:gap-[90px_22px] mq450:pl-5 mq450:pt-[55px] mq450:pb-[43px] mq450:box-border">
        <div className="w-[608px] flex flex-col items-start justify-start gap-[27px] max-w-[calc(100%_-_728px)] shrink-0 mq1100:max-w-full">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-2 box-border max-w-full">
            <h1 className="m-0 flex-1 relative text-inherit leading-[100%] font-bold font-inherit inline-block max-w-full mq750:text-15xl mq750:leading-[34px] mq450:text-7xl mq450:leading-[26px]">
              Design A Marketplace - Raise Capital - Manage Investors
            </h1>
          </div>
          <div className="flex flex-row items-start justify-start py-0 pr-5 pl-0">
            <div className="flex flex-col items-start justify-start pt-[1.5px] px-0 pb-0">
              <button className="cursor-pointer [border:none] p-5 bg-mediumseagreen-100 rounded-31xl flex flex-row items-start justify-start whitespace-nowrap hover:bg-mediumseagreen-200">
                <div className="relative text-xl leading-[14px] font-semibold font-urbanist text-darkslateblue-200 text-left">
                  Schedule a Demo
                </div>
              </button>
            </div>
            <button className="cursor-pointer [border:none] p-2.5 bg-mediumseagreen-100 h-[57px] w-[57px] rounded-31xl flex flex-row items-center justify-center box-border">
              <img
                className="h-[22px] w-[22px] relative overflow-hidden shrink-0 object-cover"
                alt=""
                src="/icon--arrow-top-right-1@2x.png"
              />
            </button>
          </div>
        </div>
        <div className="mb-[-242.4000000000001px] h-[683.9px] flex-1 flex flex-col items-end justify-start py-0 pr-[91px] pl-0 box-border gap-[60.5px] max-w-[calc(100%_-_698px)] mq750:gap-[30px_60.5px] mq750:pr-[45px] mq750:box-border mq1100:hidden mq450:gap-[15px_60.5px] mq450:pr-5 mq450:box-border">
          <img
            className="mr-[-214px] w-[397px] h-[233px] relative object-contain max-w-full"
            alt=""
            src="/vector-1.svg"
          />
          <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[68.40000000000009px] max-w-full mq750:gap-[34px_68.4px] mq450:gap-[17px_68.4px]">
            <div className="self-stretch h-[89px] flex flex-row items-start justify-end max-w-full">
              <img
                className="h-[89px] w-[444px] relative max-w-full"
                alt=""
                src="/vector-2.svg"
              />
            </div>
            <img
              className="w-[397px] h-[233px] relative object-contain max-w-full"
              alt=""
              src="/vector-3.svg"
            />
          </div>
        </div>
      </section>
      <section className="mr-[-2px] mb-[149px] self-stretch bg-whitesmoke flex flex-col items-center justify-start pt-28 px-5 pb-20 box-border gap-[60px] max-w-full text-center text-21xl text-darkslateblue-200 font-urbanist mq750:gap-[30px_60px] mq1100:pt-[73px] mq1100:pb-[52px] mq1100:box-border mq450:pt-[47px] mq450:pb-[34px] mq450:box-border">
        <div className="w-[1220px] flex flex-row items-start justify-center max-w-full">
          <h1 className="m-0 w-[806px] relative text-inherit leading-[100%] font-bold font-inherit inline-block shrink-0 max-w-full mq750:text-13xl mq750:leading-[32px] mq450:text-5xl mq450:leading-[24px]">{`Create & Design A Unique Marketplace`}</h1>
        </div>
        <div className="w-[1220px] grid flex-row items-start justify-start gap-[20.049999999999983px] max-w-full grid-cols-[repeat(3,_minmax(295px,_1fr))] text-left text-base text-base-white mq750:grid-cols-[minmax(295px,_1fr)] mq1100:justify-center mq1100:grid-cols-[repeat(2,_minmax(295px,_511px))]">
          <div className="rounded-roundness-outside-m1 bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border overflow-hidden flex flex-col items-start justify-start max-w-full border-[1px] border-solid border-stroke-base">
            <div className="self-stretch h-[250px] flex flex-row items-start justify-end pt-[178px] px-[21.30000000000001px] pb-0 box-border bg-[url('/public/image@2x.png')] bg-cover bg-no-repeat bg-[top] max-w-full">
              <img
                className="h-[250px] w-[393px] relative object-cover hidden max-w-full"
                alt=""
                src="/image@2x.png"
              />
              <div className="w-[231px] rounded-sm bg-darkslateblue-200 flex flex-col items-start justify-start py-2 pr-[17px] pl-5 box-border gap-[2px] z-[1]">
                <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
                    <b className="self-stretch relative leading-[24px]">
                      Annual Return
                    </b>
                    <b className="self-stretch relative leading-[24px]">
                      Cash On Cash
                    </b>
                  </div>
                  <div className="w-[47px] flex flex-col items-start justify-start gap-[2px]">
                    <b className="self-stretch relative leading-[24px]">
                      17.3%
                    </b>
                    <b className="w-[45px] relative leading-[24px] inline-block">
                      9.6%
                    </b>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
                  <b className="flex-1 relative leading-[24px]">
                    Equity Multiple
                  </b>
                  <b className="w-[46px] relative leading-[24px] inline-block shrink-0">
                    2.8X
                  </b>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[39px] px-8 pb-6 gap-[24px] text-5xl text-darkslateblue-200">
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-col items-start justify-start">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                    <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.01em] leading-[30px] font-bold font-inherit mq450:text-lgi mq450:leading-[24px]">
                      Commercial
                    </h3>
                    <h3 className="m-0 self-stretch relative text-inherit leading-[24px] font-bold font-inherit text-text-icons-base-second mq450:text-lgi mq450:leading-[19px]">
                      Project Value $5.6M
                    </h3>
                  </div>
                </div>
                <div className="self-stretch relative text-base leading-[24px] text-text-icons-base-second">
                  Min Investment $10.000
                </div>
              </div>
              <div className="flex flex-row items-center justify-center py-[7px] px-0">
                <button className="cursor-pointer [border:none] py-4 px-5 bg-mediumseagreen-100 rounded-31xl flex flex-row items-center justify-center whitespace-nowrap hover:bg-mediumseagreen-200">
                  <b className="relative text-base leading-[11px] inline-block font-urbanist text-darkslateblue-200 text-left min-w-[122px]">
                    View Investment
                  </b>
                </button>
                <div className="h-[42px] w-[42px] rounded-31xl bg-mediumseagreen-100 flex flex-row items-center justify-start p-2.5 box-border">
                  <img
                    className="h-[22px] w-[22px] relative overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/icon--arrow-top-right@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-roundness-outside-m1 bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border overflow-hidden flex flex-col items-start justify-start max-w-full border-[1px] border-solid border-stroke-base">
            <div className="self-stretch h-[250px] flex flex-row items-start justify-end pt-[178px] px-[21.30000000000001px] pb-0 box-border bg-[url('/public/image@2x.png')] bg-cover bg-no-repeat bg-[top] max-w-full">
              <img
                className="h-[250px] w-[393px] relative object-cover hidden max-w-full"
                alt=""
                src="/image@2x.png"
              />
              <div className="w-[231px] rounded-sm bg-darkslateblue-200 flex flex-col items-start justify-start py-2 pr-[17px] pl-5 box-border gap-[2px] z-[1]">
                <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
                    <b className="self-stretch relative leading-[24px]">
                      Annual Return
                    </b>
                    <b className="self-stretch relative leading-[24px]">
                      Cash On Cash
                    </b>
                  </div>
                  <div className="w-[47px] flex flex-col items-start justify-start gap-[2px]">
                    <b className="self-stretch relative leading-[24px]">
                      17.3%
                    </b>
                    <b className="w-[45px] relative leading-[24px] inline-block">
                      9.6%
                    </b>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
                  <b className="flex-1 relative leading-[24px]">
                    Equity Multiple
                  </b>
                  <b className="w-[46px] relative leading-[24px] inline-block shrink-0">
                    2.8X
                  </b>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[39px] px-8 pb-6 gap-[24px] text-5xl text-darkslateblue-200">
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-col items-start justify-start">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                    <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.01em] leading-[30px] font-bold font-inherit mq450:text-lgi mq450:leading-[24px]">
                      Residential
                    </h3>
                    <h3 className="m-0 self-stretch relative text-inherit leading-[24px] font-bold font-inherit text-text-icons-base-second mq450:text-lgi mq450:leading-[19px]">
                      Project Value $10.6M
                    </h3>
                  </div>
                </div>
                <div className="self-stretch relative text-base leading-[24px] text-text-icons-base-second">
                  Min Investment $10.000
                </div>
              </div>
              <div className="flex flex-row items-center justify-center py-[7px] pr-[1.4210854715202004e-14px] pl-0">
                <button className="cursor-pointer [border:none] py-4 px-5 bg-mediumseagreen-100 rounded-31xl flex flex-row items-center justify-center whitespace-nowrap hover:bg-mediumseagreen-200">
                  <b className="relative text-base leading-[11px] inline-block font-urbanist text-darkslateblue-200 text-left min-w-[122px]">
                    View Investment
                  </b>
                </button>
                <div className="h-[42px] w-[42px] rounded-31xl bg-mediumseagreen-100 flex flex-row items-center justify-start py-2.5 pr-[9.999999999999996px] pl-2.5 box-border z-[1]">
                  <img
                    className="h-[22px] w-[22px] relative overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/icon--arrow-top-right-3@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-roundness-outside-m1 bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border overflow-hidden flex flex-col items-start justify-start max-w-full border-[1px] border-solid border-stroke-base">
            <div className="self-stretch h-[250px] flex flex-row items-start justify-end pt-[178px] px-[21.299999999999955px] pb-0 box-border bg-[url('/public/image@2x.png')] bg-cover bg-no-repeat bg-[top] max-w-full">
              <img
                className="h-[250px] w-[393px] relative object-cover hidden max-w-full"
                alt=""
                src="/image@2x.png"
              />
              <div className="w-[231px] rounded-sm bg-darkslateblue-200 flex flex-col items-start justify-start py-2 pr-[17px] pl-5 box-border gap-[2px] z-[1]">
                <div className="self-stretch flex flex-row items-start justify-start gap-[16.000000000000057px]">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
                    <b className="self-stretch relative leading-[24px]">
                      Annual Return
                    </b>
                    <b className="self-stretch relative leading-[24px]">
                      Cash On Cash
                    </b>
                  </div>
                  <div className="w-[47px] flex flex-col items-start justify-start gap-[2px]">
                    <b className="self-stretch relative leading-[24px]">
                      17.3%
                    </b>
                    <b className="w-[45px] relative leading-[24px] inline-block">
                      9.6%
                    </b>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-[16.000000000000057px]">
                  <b className="flex-1 relative leading-[24px]">
                    Equity Multiple
                  </b>
                  <b className="w-[46px] relative leading-[24px] inline-block shrink-0">
                    2.8X
                  </b>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-[39px] px-8 pb-6 gap-[24px] text-5xl text-darkslateblue-200">
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-col items-start justify-start">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                    <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.01em] leading-[30px] font-bold font-inherit mq450:text-lgi mq450:leading-[24px]">
                      Industrial
                    </h3>
                    <h3 className="m-0 self-stretch relative text-inherit leading-[24px] font-bold font-inherit text-text-icons-base-second mq450:text-lgi mq450:leading-[19px]">
                      Project Value $7.8M
                    </h3>
                  </div>
                </div>
                <div className="self-stretch relative text-base leading-[24px] text-text-icons-base-second">
                  Min Investment $10.000
                </div>
              </div>
              <div className="flex flex-row items-center justify-center py-[7px] px-0">
                <button className="cursor-pointer [border:none] py-4 px-5 bg-mediumseagreen-100 rounded-31xl flex flex-row items-center justify-center whitespace-nowrap hover:bg-mediumseagreen-200">
                  <b className="relative text-base leading-[11px] inline-block font-urbanist text-darkslateblue-200 text-left min-w-[122px]">
                    View Investment
                  </b>
                </button>
                <div className="h-[42px] w-[42px] rounded-31xl bg-mediumseagreen-100 flex flex-row items-center justify-start p-2.5 box-border">
                  <img
                    className="h-[22px] w-[22px] relative overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/icon--arrow-top-right-4@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1411px] flex flex-row items-start justify-center pt-0 px-5 pb-[94px] box-border max-w-full text-left text-21xl text-mediumseagreen-100 font-urbanist mq750:pb-10 mq750:box-border mq1100:pb-[61px] mq1100:box-border">
        <div className="w-[1225px] flex flex-col items-start justify-start gap-[60px] max-w-full mq750:gap-[30px_60px]">
          <div className="w-[1197px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
            <h1 className="m-0 w-[652px] relative text-inherit leading-[100%] font-bold font-inherit inline-block shrink-0 max-w-full mq750:text-13xl mq750:leading-[32px] mq450:text-5xl mq450:leading-[24px]">
              Create Your Custom Island today
            </h1>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[19px] max-w-full text-5xl text-darkslateblue-200">
            <div className="self-stretch flex flex-row flex-wrap items-start justify-center max-w-full [row-gap:20px]">
              <div className="w-[248.5px] flex flex-col items-start justify-start pt-9 px-0 pb-0 box-border">
                <div className="self-stretch flex flex-row items-start justify-start relative">
                  <h3 className="!m-[0] absolute top-[112px] right-[-23.5px] text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit inline-block min-w-[100px] mq450:text-lgi mq450:leading-[24px]">
                    FUNDING
                  </h3>
                  <div className="flex-1 flex flex-col items-start justify-start gap-[237px] text-13xl text-mediumseagreen-100">
                    <h1 className="m-0 w-[183px] relative text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit inline-block mq750:text-7xl mq750:leading-[24px] mq450:text-lgi mq450:leading-[18px]">
                      OFF CHAIN
                    </h1>
                    <div className="self-stretch flex flex-row items-start justify-end text-5xl text-darkslateblue-200">
                      <div className="w-[171.5px] flex flex-col items-start justify-start gap-[154px]">
                        <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit mq450:text-lgi mq450:leading-[24px]">
                          COMPANY
                        </h3>
                        <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-3.5">
                          <h3 className="m-0 flex-1 relative text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit mq450:text-lgi mq450:leading-[24px]">
                            INVESTORS
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start py-0 pr-[16.5px] pl-0 box-border min-w-[466px] max-w-full mq750:min-w-full">
                <div className="self-stretch flex flex-row items-start justify-start relative max-w-full">
                  <h3 className="!m-[0] w-[213px] absolute right-[-198.5px] bottom-[204px] text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit inline-block mq450:text-lgi mq450:leading-[24px]">
                    APRECIATION
                  </h3>
                  <div className="flex-1 overflow-hidden flex flex-col items-start justify-start gap-[9.200000000000044px] max-w-full z-[1]">
                    <div className="self-stretch flex flex-row items-start justify-start pt-0 pb-[8.099999999999909px] pr-0 pl-[3px] box-border max-w-full">
                      <div className="w-[716px] flex flex-row items-start justify-between max-w-[103%] shrink-0 gap-[20px] mq750:flex-wrap">
                        <div className="w-[295.6px] flex flex-col items-start justify-start pt-px px-0 pb-0 box-border">
                          <div className="self-stretch flex flex-row items-start justify-start gap-[11.400000000000006px]">
                            <div className="flex-1 flex flex-col items-start justify-start pt-[23px] px-0 pb-0">
                              <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit mq450:text-lgi mq450:leading-[24px]">
                                WALLET ADDRESS
                              </h3>
                            </div>
                            <div className="h-[81.5px] w-[71.2px] relative">
                              <img
                                className="absolute top-[10.3px] left-[7.7px] w-[14.2px] h-[24.6px]"
                                alt=""
                                src="/vector-4.svg"
                              />
                              <img
                                className="absolute top-[10.3px] left-[49.2px] w-[14.2px] h-[24.6px]"
                                alt=""
                                src="/vector-5.svg"
                              />
                              <img
                                className="absolute top-[12.9px] left-[33.6px] w-[3.9px] h-[22px]"
                                alt=""
                                src="/vector-6.svg"
                              />
                              <img
                                className="absolute top-[0px] left-[28.5px] w-[14.2px] h-[14.2px] z-[1]"
                                alt=""
                                src="/vector-7.svg"
                              />
                              <img
                                className="absolute top-[0px] left-[2.6px] w-[14.2px] h-[14.2px] z-[1]"
                                alt=""
                                src="/vector-8.svg"
                              />
                              <img
                                className="absolute top-[0px] left-[54.3px] w-[14.2px] h-[14.2px] z-[1]"
                                alt=""
                                src="/vector-9.svg"
                              />
                              <div className="absolute top-[31px] left-[0px] w-[66px] h-[50.5px]">
                                <img
                                  className="absolute top-[0px] left-[0px] w-[66px] h-[50.5px] z-[1]"
                                  alt=""
                                  src="/vector-10.svg"
                                />
                                <img
                                  className="absolute top-[31.1px] left-[25.9px] w-[40.1px] h-[19.4px] z-[2]"
                                  alt=""
                                  src="/vector-11.svg"
                                />
                              </div>
                              <img
                                className="absolute top-[46.6px] left-[46.6px] w-[24.6px] h-[19.4px] z-[3]"
                                alt=""
                                src="/vector-12.svg"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="h-[84.2px] w-[308px] flex flex-row items-start justify-start gap-[22.69999999999999px]">
                          <div className="self-stretch w-[84.3px] flex flex-row items-end justify-end pt-[11px] px-[21.500000000000018px] pb-[10.700000000000044px] box-border relative">
                            <div className="h-[25.4px] w-1 relative">
                              <img
                                className="absolute top-[0px] left-[0px] w-1 h-[25.4px]"
                                alt=""
                                src="/vector-13.svg"
                              />
                              <img
                                className="absolute top-[0px] left-[0px] w-1 h-[25.4px] z-[2]"
                                alt=""
                                src="/vector-13.svg"
                              />
                            </div>
                            <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px]">
                              <img
                                className="absolute top-[16px] left-[40.1px] w-1 h-[25.4px]"
                                alt=""
                                src="/vector-15.svg"
                              />
                              <img
                                className="absolute top-[29.4px] left-[16px] w-[17.4px] h-[17.4px]"
                                alt=""
                                src="/vector-16.svg"
                              />
                              <img
                                className="absolute top-[29.4px] left-[50.8px] w-[17.4px] h-[17.4px]"
                                alt=""
                                src="/vector-17.svg"
                              />
                              <img
                                className="absolute top-[37.5px] left-[21.4px] w-[41.5px] h-[25.4px] z-[1]"
                                alt=""
                                src="/vector-18.svg"
                              />
                              <img
                                className="absolute top-[69.5px] left-[36.3px] w-[26.5px] h-[14.7px] z-[1]"
                                alt=""
                                src="/vector-19.svg"
                              />
                              <img
                                className="absolute top-[69.5px] left-[21.4px] w-[9.8px] h-[7.3px]"
                                alt=""
                                src="/vector-20.svg"
                              />
                              <img
                                className="absolute top-[48.1px] left-[21.4px] w-1 h-[25.4px] z-[2]"
                                alt=""
                                src="/vector-21.svg"
                              />
                              <img
                                className="absolute top-[58.8px] left-[40.1px] w-1 h-[25.4px] z-[2]"
                                alt=""
                                src="/vector-22.svg"
                              />
                              <input
                                className="m-0 absolute top-[0px] left-[32.1px] w-[20.1px] h-[20.1px] z-[1]"
                                type="checkbox"
                              />
                              <input
                                className="m-0 absolute top-[21.4px] left-[0px] w-[20.1px] h-[20.1px] z-[1]"
                                type="checkbox"
                              />
                              <input
                                className="m-0 absolute top-[21.4px] left-[64.2px] w-[20.1px] h-[20.1px] z-[1]"
                                type="checkbox"
                              />
                            </div>
                          </div>
                          <div className="flex-1 flex flex-col items-start justify-start pt-[15px] px-0 pb-0">
                            <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit mq450:text-lgi mq450:leading-[24px]">
                              DISTRIBUTION
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-[685.8px] flex flex-row items-start justify-start pt-0 pb-[13.399999999999975px] pr-[46px] pl-[45.69999999999999px] box-border max-w-full mq750:pl-[23px] mq750:pr-[23px] mq750:box-border">
                      <div className="flex-1 flex flex-col items-start justify-start gap-[1.0499999999999543px] max-w-full">
                        <div className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-[1.7999999999999543px] relative gap-[20px] mq450:flex-wrap">
                          <div className="h-[84.2px] w-[84.1px] flex flex-row items-start justify-start relative">
                            <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px]">
                              <img
                                className="absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/vector-26.svg"
                              />
                              <img
                                className="absolute top-[31.2px] left-[47.2px] w-[12.8px] h-[26.1px] z-[1]"
                                alt=""
                                src="/vector-27.svg"
                              />
                            </div>
                          </div>
                          <div className="w-[77.3px] flex flex-col items-start justify-start pt-[5.2000000000000455px] px-0 pb-0 box-border">
                            <div className="self-stretch h-[88.5px] relative">
                              <img
                                className="absolute top-[24.5px] left-[21px] w-[35.1px] h-[21.5px] z-[1]"
                                alt=""
                                src="/vector-28.svg"
                              />
                              <img
                                className="absolute top-[51.6px] left-[21px] w-[35.2px] h-[12.5px] z-[1]"
                                alt=""
                                src="/vector-29.svg"
                              />
                              <img
                                className="absolute top-[33.5px] left-[21px] w-[3.4px] h-[21.5px] z-[2]"
                                alt=""
                                src="/vector-30.svg"
                              />
                              <div className="absolute top-[33.5px] left-[52.8px] w-[3.4px] h-[21.5px]">
                                <img
                                  className="absolute top-[0px] left-[0px] w-[3.4px] h-[21.5px]"
                                  alt=""
                                  src="/vector-31.svg"
                                />
                                <img
                                  className="absolute top-[0px] left-[0px] w-[3.4px] h-[21.5px] z-[2]"
                                  alt=""
                                  src="/vector-31.svg"
                                />
                              </div>
                              <img
                                className="absolute top-[42.6px] left-[36.9px] w-[3.4px] h-[21.5px] z-[2]"
                                alt=""
                                src="/vector-33.svg"
                              />
                              <img
                                className="absolute top-[0px] left-[0px] w-[77.3px] h-[88.5px] z-[3]"
                                alt=""
                                src="/vector-34.svg"
                              />
                            </div>
                          </div>
                          <img
                            className="h-[3px] w-[3.7px] absolute !m-[0] bottom-[0px] left-[119.8px]"
                            alt=""
                            src="/vector-35.svg"
                          />
                          <img
                            className="h-1 w-[2.2px] absolute !m-[0] top-[12.3px] left-[249.3px]"
                            alt=""
                            src="/vector-36.svg"
                          />
                          <div className="h-[320.6px] w-[275.9px] absolute !m-[0] right-[86.9px] bottom-[-244.7px]">
                            <img
                              className="absolute top-[154.4px] left-[0px] w-[135.9px] h-[132.7px]"
                              alt=""
                              src="/vector-37.svg"
                            />
                            <img
                              className="absolute top-[110.9px] left-[110.6px] w-[2.7px] h-[3.9px]"
                              alt=""
                              src="/vector-38.svg"
                            />
                            <img
                              className="absolute top-[10.6px] left-[113.9px] w-[47.5px] h-[97.4px]"
                              alt=""
                              src="/vector-39.svg"
                            />
                            <img
                              className="absolute top-[3.8px] left-[161.9px] w-[2.7px] h-[3.9px]"
                              alt=""
                              src="/vector-40.svg"
                            />
                            <img
                              className="absolute top-[161.7px] left-[159.2px] w-[3.5px] h-[3.2px]"
                              alt=""
                              src="/vector-41.svg"
                            />
                            <img
                              className="absolute top-[87.3px] left-[164.9px] w-[89.1px] h-[73px]"
                              alt=""
                              src="/vector-42.svg"
                            />
                            <img
                              className="absolute top-[82.7px] left-[256.2px] w-[3.5px] h-[3.2px]"
                              alt=""
                              src="/vector-43.svg"
                            />
                            <img
                              className="absolute top-[269.2px] left-[177.3px] w-[98.6px] h-[51.4px]"
                              alt=""
                              src="/vector-44.svg"
                            />
                            <img
                              className="absolute top-[97.4px] left-[39.5px] w-[2.2px] h-1"
                              alt=""
                              src="/vector-45.svg"
                            />
                            <img
                              className="absolute top-[0px] left-[19.2px] w-[20.9px] h-[94.2px]"
                              alt=""
                              src="/vector-46.svg"
                            />
                            <img
                              className="absolute top-[42.7px] left-[145.4px] w-[98.5px] h-[179.7px] z-[1]"
                              alt=""
                              src="/vector-47.svg"
                            />
                            <img
                              className="absolute top-[193.6px] left-[213.3px] w-[50px] h-[27.7px] z-[2]"
                              alt=""
                              src="/vector-48.svg"
                            />
                          </div>
                        </div>
                        <div className="w-[334px] h-[57.7px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
                          <img
                            className="h-[57.7px] w-[82.6px] relative"
                            alt=""
                            src="/vector-49.svg"
                          />
                        </div>
                        <div className="w-[424.9px] h-[3px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
                          <img
                            className="h-[3px] w-[3.7px] relative"
                            alt=""
                            src="/vector-50.svg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch h-[271.4px] flex flex-col items-start justify-start pt-0 px-0 pb-[7.399999999999977px] box-border gap-[3.3000000000000114px] max-w-full mq750:h-auto">
                      <div className="self-stretch flex flex-row items-end justify-start gap-[61.8px] shrink-0 [debug_commit:f6aba90] mq750:flex-wrap mq750:gap-[61.8px_31px] mq450:gap-[61.8px_15px]">
                        <div className="w-[273.5px] flex flex-col items-start justify-end pt-0 px-0 pb-[16.5px] box-border">
                          <div className="self-stretch flex flex-row items-end justify-start gap-[23.90000000000001px]">
                            <div className="w-[99.2px] flex flex-col items-start justify-start gap-[100.5px]">
                              <div className="w-[69.1px] h-[72.5px] flex flex-row items-start justify-start relative">
                                <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px]">
                                  <img
                                    className="absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/vector-51.svg"
                                  />
                                  <img
                                    className="absolute top-[12.4px] left-[11.6px] w-[14.4px] h-[2.7px] z-[1]"
                                    alt=""
                                    src="/vector-52.svg"
                                  />
                                  <img
                                    className="absolute top-[21.7px] left-[11.6px] w-[30px] h-[2.7px] z-[1]"
                                    alt=""
                                    src="/vector-53.svg"
                                  />
                                  <img
                                    className="absolute top-[31px] left-[11.6px] w-[30px] h-[2.7px] z-[1]"
                                    alt=""
                                    src="/vector-54.svg"
                                  />
                                  <img
                                    className="absolute top-[40.3px] left-[11.6px] w-[30px] h-[2.7px] z-[1]"
                                    alt=""
                                    src="/vector-55.svg"
                                  />
                                </div>
                              </div>
                              <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[18.69999999999999px]">
                                <div className="h-[74.5px] flex-1 relative">
                                  <img
                                    className="absolute top-[23.9px] left-[24.3px] w-[33.1px] h-[26.7px]"
                                    alt=""
                                    src="/vector-56.svg"
                                  />
                                  <img
                                    className="absolute top-[0px] left-[27.5px] w-[26.7px] h-[26.7px] z-[1]"
                                    alt=""
                                    src="/vector-57.svg"
                                  />
                                  <img
                                    className="absolute top-[49.2px] left-[49.2px] w-[29.9px] h-[23.9px] z-[1]"
                                    alt=""
                                    src="/vector-58.svg"
                                  />
                                  <img
                                    className="absolute top-[47.8px] left-[47.8px] w-[32.7px] h-[26.7px] z-[2]"
                                    alt=""
                                    src="/vector-59.svg"
                                  />
                                  <img
                                    className="absolute top-[25.3px] left-[52.2px] w-[23.9px] h-[23.9px] z-[3]"
                                    alt=""
                                    src="/vector-60.svg"
                                  />
                                  <img
                                    className="absolute top-[23.9px] left-[50.8px] w-[26.7px] h-[26.7px] z-[4]"
                                    alt=""
                                    src="/vector-61.svg"
                                  />
                                  <img
                                    className="absolute top-[47.6px] left-[1.4px] w-[32.3px] h-[23.9px] z-[1]"
                                    alt=""
                                    src="/vector-62.svg"
                                  />
                                  <img
                                    className="absolute top-[46.2px] left-[0px] w-[35.1px] h-[26.7px] z-[2]"
                                    alt=""
                                    src="/vector-63.svg"
                                  />
                                  <img
                                    className="absolute top-[23.7px] left-[5.6px] w-[23.9px] h-[23.9px] z-[3]"
                                    alt=""
                                    src="/vector-64.svg"
                                  />
                                  <img
                                    className="absolute top-[22.3px] left-[4.2px] w-[26.7px] h-[26.7px] z-[4]"
                                    alt=""
                                    src="/vector-65.svg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[7.399999999999977px]">
                              <div className="self-stretch flex flex-row items-end justify-start gap-[1.1999999999999886px]">
                                <div className="flex-1 flex flex-col items-end justify-start gap-[2.6000000000000227px]">
                                  <div className="self-stretch h-[193.7px] relative">
                                    <img
                                      className="absolute top-[120.4px] left-[96.7px] w-[49.5px] h-[73.3px]"
                                      alt=""
                                      src="/vector-66.svg"
                                    />
                                    <img
                                      className="absolute top-[56.2px] left-[116.1px] w-[3.8px] h-[2.7px]"
                                      alt=""
                                      src="/vector-67.svg"
                                    />
                                    <img
                                      className="absolute top-[59.6px] left-[16.9px] w-[96.4px] h-[49.1px]"
                                      alt=""
                                      src="/vector-68.svg"
                                    />
                                    <img
                                      className="absolute top-[109.3px] left-[10.2px] w-[3.8px] h-[2.7px]"
                                      alt=""
                                      src="/vector-69.svg"
                                    />
                                    <img
                                      className="absolute top-[0.2px] left-[0px] w-[120px] h-[1.6px]"
                                      alt=""
                                      src="/vector-70.svg"
                                    />
                                    <img
                                      className="absolute top-[0px] left-[43.4px] w-[77.4px] h-[159.9px] z-[1]"
                                      alt=""
                                      src="/vector-71.svg"
                                    />
                                    <img
                                      className="absolute top-[1px] left-[20px] w-[50px] h-[27.7px] z-[2]"
                                      alt=""
                                      src="/vector-72.svg"
                                    />
                                  </div>
                                  <div className="h-[3.7px] flex flex-row items-start justify-end py-0 pr-[50.70000000000002px] pl-[51px] box-border">
                                    <img
                                      className="h-[3.7px] w-[3px] relative"
                                      alt=""
                                      src="/vector-73.svg"
                                    />
                                  </div>
                                </div>
                                <div className="h-[85.8px] flex flex-col items-start justify-start">
                                  <img
                                    className="w-[3px] h-[3.7px] relative"
                                    alt=""
                                    src="/vector-74.svg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="h-[103.1px] flex flex-col items-start justify-start py-0 pr-[9px] pl-0 box-border gap-[3.2999999999999545px]">
                          <img
                            className="w-[1.6px] h-[4.1px] relative"
                            alt=""
                            src="/vector-75.svg"
                          />
                          <img
                            className="w-[1.6px] h-[95.7px] relative"
                            alt=""
                            src="/vector-76.svg"
                          />
                        </div>
                        <div className="h-[254.4px] w-[293.5px] flex flex-col items-start justify-end pt-0 px-0 pb-[20.09999999999999px] box-border">
                          <div className="self-stretch flex-1 flex flex-row items-end justify-start gap-[1.1000000000000014px]">
                            <div className="h-[85.2px] flex flex-col items-start justify-start">
                              <img
                                className="w-[3.1px] h-[3.6px] relative shrink-0 [debug_commit:f6aba90]"
                                alt=""
                                src="/vector-77.svg"
                              />
                            </div>
                            <div className="self-stretch flex-1 flex flex-col items-end justify-start gap-[44px] shrink-0 [debug_commit:f6aba90] mq450:gap-[22px_44px]">
                              <div className="w-[252.7px] flex-1 flex flex-col items-start justify-start gap-[25.59999999999997px]">
                                <div className="self-stretch flex flex-row items-end justify-between gap-[20px]">
                                  <div className="w-[125.6px] flex flex-col items-start justify-end pt-0 px-0 pb-[18.59999999999997px] box-border">
                                    <div className="self-stretch h-[1.6px] flex flex-row items-start justify-start gap-[3.1000000000000156px] shrink-0 [debug_commit:f6aba90]">
                                      <img
                                        className="h-[1.6px] w-[4.1px] relative min-h-[2px]"
                                        alt=""
                                        src="/vector-78.svg"
                                      />
                                      <img
                                        className="h-[1.6px] w-[111.2px] relative min-h-[2px]"
                                        alt=""
                                        src="/vector-79.svg"
                                      />
                                      <img
                                        className="h-[1.6px] w-[4.1px] relative min-h-[2px]"
                                        alt=""
                                        src="/vector-80.svg"
                                      />
                                    </div>
                                  </div>
                                  <div className="w-[69.1px] flex flex-row items-start justify-start shrink-0 [debug_commit:f6aba90]">
                                    <div className="h-[69.3px] flex-1 relative">
                                      <img
                                        className="absolute top-[0.4px] left-[0.4px] w-[68.3px] h-[68.5px]"
                                        alt=""
                                        src="/vector-81.svg"
                                      />
                                      <div className="absolute top-[0px] left-[0px] w-full h-full">
                                        <img
                                          className="absolute top-[0px] left-[0px] w-[69.1px] h-[69.3px] z-[1]"
                                          alt=""
                                          src="/vector-82.svg"
                                        />
                                        <div className="absolute top-[20.8px] left-[18.7px] w-[31.6px] h-[31.6px]">
                                          <img
                                            className="absolute top-[0px] left-[0px] w-[31.6px] h-[31.6px] z-[2]"
                                            alt=""
                                            src="/vector-83.svg"
                                          />
                                          <img
                                            className="absolute top-[5.9px] left-[11.2px] w-[9.3px] h-[19.7px] z-[3]"
                                            alt=""
                                            src="/vector-84.svg"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  className="w-[3.8px] h-[2.8px] relative"
                                  alt=""
                                  src="/vector-85.svg"
                                />
                              </div>
                              <div className="self-stretch flex-1 flex flex-row items-start justify-end py-0 pr-[23px] pl-0">
                                <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[1.099999999999966px]">
                                  <div className="self-stretch flex-1 flex flex-row items-end justify-between relative gap-[20px]">
                                    <div className="h-[74.7px] w-[54.1px] flex flex-col items-start justify-end pt-0 px-0 pb-[1.0000000000000568px] box-border">
                                      <img
                                        className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full"
                                        alt=""
                                        src="/vector-86.svg"
                                      />
                                    </div>
                                    <img
                                      className="h-[2.8px] w-[3.8px] absolute !m-[0] top-[8.5px] right-[118.4px]"
                                      alt=""
                                      src="/vector-87.svg"
                                    />
                                    <img
                                      className="h-[87.9px] w-[84.2px] relative"
                                      alt=""
                                      src="/vector-88.svg"
                                    />
                                  </div>
                                  <div className="h-[3.6px] flex flex-row items-start justify-start py-0 pr-[55px] pl-[55.2px] box-border">
                                    <img
                                      className="h-[3.6px] w-[3.1px] relative"
                                      alt=""
                                      src="/vector-89.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-[672.2px] h-[4.1px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
                        <img
                          className="h-[4.1px] w-[1.6px] relative shrink-0 [debug_commit:f6aba90]"
                          alt=""
                          src="/vector-90.svg"
                        />
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[130.9px] box-border max-w-full mq750:pl-[65px] mq750:box-border mq450:pl-5 mq450:box-border">
                      <div className="w-[647.6px] flex flex-row items-start justify-between max-w-[114%] shrink-0 gap-[20px] mq750:flex-wrap">
                        <div className="w-[68.3px] flex flex-col items-start justify-start pt-[6.099999999999994px] px-0 pb-0 box-border">
                          <div className="self-stretch h-[86.1px] relative">
                            <img
                              className="absolute top-[28.2px] left-[16.2px] w-[35.9px] h-[3.2px]"
                              alt=""
                              src="/vector-91.svg"
                            />
                            <img
                              className="absolute top-[37.7px] left-[16.2px] w-[35.9px] h-[3.2px]"
                              alt=""
                              src="/vector-92.svg"
                            />
                            <img
                              className="absolute top-[47.2px] left-[16.2px] w-[19.2px] h-[3.2px]"
                              alt=""
                              src="/vector-93.svg"
                            />
                            <img
                              className="absolute top-[0px] left-[0px] w-[68.3px] h-[86.1px] z-[1]"
                              alt=""
                              src="/vector-94.svg"
                            />
                          </div>
                        </div>
                        <div className="w-[78.2px] flex flex-col items-start justify-start pt-[47.400000000000006px] px-0 pb-0 box-border">
                          <div className="self-stretch h-[89.6px] relative">
                            <img
                              className="absolute top-[11.4px] left-[8.5px] w-[15.6px] h-[27px]"
                              alt=""
                              src="/vector-95.svg"
                            />
                            <img
                              className="absolute top-[11.4px] left-[54.1px] w-[15.6px] h-[27px]"
                              alt=""
                              src="/vector-96.svg"
                            />
                            <img
                              className="absolute top-[14.2px] left-[37px] w-[4.3px] h-[24.2px]"
                              alt=""
                              src="/vector-97.svg"
                            />
                            <img
                              className="absolute top-[0px] left-[31.3px] w-[15.7px] h-[15.6px] z-[1]"
                              alt=""
                              src="/vector-98.svg"
                            />
                            <img
                              className="absolute top-[0px] left-[2.8px] w-[15.7px] h-[15.6px] z-[1]"
                              alt=""
                              src="/vector-99.svg"
                            />
                            <img
                              className="absolute top-[0px] left-[59.8px] w-[15.7px] h-[15.6px] z-[1]"
                              alt=""
                              src="/vector-100.svg"
                            />
                            <div className="absolute top-[34.1px] left-[0px] w-[72.6px] h-[55.5px]">
                              <img
                                className="absolute top-[0px] left-[0px] w-[72.6px] h-[55.5px] z-[1]"
                                alt=""
                                src="/vector-101.svg"
                              />
                              <img
                                className="absolute top-[34.2px] left-[28.5px] w-[44.1px] h-[21.3px] z-[2]"
                                alt=""
                                src="/vector-102.svg"
                              />
                            </div>
                            <img
                              className="absolute top-[51.2px] left-[51.2px] w-[27px] h-[21.3px] z-[3]"
                              alt=""
                              src="/vector-103.svg"
                            />
                          </div>
                        </div>
                        <div className="w-[292.1px] flex flex-row items-start justify-start gap-[17.19999999999999px]">
                          <div className="h-[90.7px] w-[61.9px] flex flex-row items-start justify-start relative">
                            <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px]">
                              <img
                                className="absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/vector-104.svg"
                              />
                              <img
                                className="absolute top-[41.5px] left-[22.7px] w-[16.4px] h-[36.6px] z-[1]"
                                alt=""
                                src="/vector-105.svg"
                              />
                            </div>
                          </div>
                          <div className="flex-1 flex flex-col items-start justify-start pt-[52px] px-0 pb-0">
                            <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit mq450:text-lgi mq450:leading-[24px]">
                              RENT / SALE
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="!m-[0] w-[213px] absolute bottom-[47px] left-[-102.5px] text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit text-right inline-block z-[5] mq450:text-lgi mq450:leading-[24px]">
                    S.E.C REGULATIONS
                  </h3>
                </div>
              </div>
              <div className="w-[259px] flex flex-col items-start justify-start pt-9 px-0 pb-0 box-border">
                <div className="self-stretch flex flex-row items-start justify-start relative">
                  <h3 className="!m-[0] w-[132px] absolute top-[102px] left-[-56px] text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit inline-block mq450:text-lgi mq450:leading-[24px]">
                    SECURITY
                  </h3>
                  <div className="flex-1 flex flex-col items-start justify-start gap-[252px] text-13xl text-mediumseagreen-100 mq450:gap-[126px_252px]">
                    <div className="self-stretch flex flex-row items-start justify-end">
                      <h1 className="m-0 w-[183px] relative text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit inline-block shrink-0 mq750:text-7xl mq750:leading-[24px] mq450:text-lgi mq450:leading-[18px]">
                        ON CHAIN
                      </h1>
                    </div>
                    <h3 className="m-0 relative text-5xl tracking-[-0.01em] leading-[30px] font-extrabold font-inherit text-darkslateblue-200 mq450:text-lgi mq450:leading-[24px]">
                      ACQUIRING
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[1163px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
              <h3 className="m-0 w-[213px] relative text-inherit tracking-[-0.01em] leading-[30px] font-extrabold font-inherit inline-block shrink-0 mq450:text-lgi mq450:leading-[24px]">
                WALLET ADDRESS
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section className="mr-[-2px] self-stretch bg-whitesmoke flex flex-col items-center justify-center py-20 px-5 box-border max-w-full text-left text-21xl text-darkslateblue-200 font-urbanist mq750:pt-[52px] mq750:pb-[52px] mq750:box-border">
        <div className="w-[1220px] flex flex-col items-start justify-start gap-[60px] max-w-full mq750:gap-[30px_60px]">
          <h1 className="m-0 w-[600px] relative text-inherit leading-[100%] font-bold font-inherit inline-block max-w-full mq750:text-13xl mq750:leading-[32px] mq450:text-5xl mq450:leading-[24px]">
            Trustworthy Technology
          </h1>
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[47px] pl-0 box-border gap-[20px] max-w-full text-xl font-barlow mq1100:flex-wrap mq1275:pr-[23px] mq1275:box-border">
            <div className="w-[475px] flex flex-col items-start justify-start min-w-[475px] max-w-full mq750:min-w-full mq1100:flex-1">
              <div className="w-[429px] flex flex-col items-start justify-start py-0 pr-2.5 pl-0 box-border max-w-full">
                <b className="self-stretch relative leading-[140%] mq450:text-base mq450:leading-[22px]">
                  Trustworthy Blockchain ensures a clear and immutable record of
                  ownership, streamlining the process of verifying property
                  ownership and minimizing disputes. Developers can benefit from
                  a transparent system that fosters trust among investors and
                  stakeholders.
                </b>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[24px] min-w-[441px] max-w-full text-dimgray mq750:min-w-full">
              <div className="self-stretch flex flex-row items-start justify-start py-0 px-0 box-border gap-[24px] max-w-full mq750:flex-wrap">
                <img
                  className="h-[28.6px] w-[25.3px] relative overflow-hidden shrink-0"
                  alt=""
                  src="/modo-de-aislamiento.svg"
                />
                <div className="flex-1 relative leading-[140%] inline-block min-w-[409px] max-w-full mq750:min-w-full mq450:text-base mq450:leading-[22px]">
                  Tokenization allows developers to offer a variety of real
                  estate assets, from residential to commercial properties. This
                  enables investors to diversify their portfolios easily,
                  spreading risk across different types of properties and
                  locations, ultimately promoting sustainable growth.
                </div>
              </div>
              <div className="w-[659px] flex flex-row items-start justify-start py-0 px-0 box-border gap-[24px] max-w-full mq750:flex-wrap">
                <img
                  className="h-[28.6px] w-[25.3px] relative overflow-hidden shrink-0"
                  alt=""
                  src="/modo-de-aislamiento.svg"
                />
                <div className="flex-1 relative leading-[140%] inline-block min-w-[396px] max-w-full mq750:min-w-full mq450:text-base mq450:leading-[22px]">
                  Blockchain technology ensures the security and integrity of
                  property records. Once information is recorded on the
                  blockchain, it becomes tamper-proof and resistant to
                  fraud.This reduces the risk of fraudulent activities in real
                  estate transactions and builds confidence among investors.
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start py-0 px-0 box-border gap-[24px] max-w-full mq750:flex-wrap">
                <img
                  className="h-[28.6px] w-[25.3px] relative overflow-hidden shrink-0"
                  alt=""
                  src="/modo-de-aislamiento-2.svg"
                />
                <div className="flex-1 relative leading-[140%] inline-block min-w-[409px] max-w-full mq750:min-w-full mq450:text-base mq450:leading-[22px]">
                  Tokenization allows for the fractional ownership of real
                  estate assets. This means that investors can buy and trade
                  smaller portions of a property, reducing the financial barrier
                  to entry. This opens up real estate investment opportunities
                  to a broader range of investors, increasing liquidity in the
                  market.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mr-[-2px] self-stretch bg-mediumseagreen-100 flex flex-col items-center justify-center py-20 px-5 box-border max-w-full z-[1] text-center text-21xl text-darkslateblue-200 font-urbanist mq450:pt-[52px] mq450:pb-[52px] mq450:box-border">
        <div className="w-[1220px] flex flex-col items-center justify-start gap-[60px] max-w-full mq750:gap-[30px_60px]">
          <h1 className="m-0 w-[806px] relative text-inherit leading-[100%] font-bold font-inherit inline-block max-w-full mq750:text-13xl mq750:leading-[32px] mq450:text-5xl mq450:leading-[24px]">
            Marketplace Process
          </h1>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-center py-0 pr-0.5 pl-0 box-border gap-[32px_30px] max-w-full text-mini text-text-icons-base-second font-text-l-regular">
            <div className="flex-1 flex flex-col items-start justify-start gap-[32px] min-w-[335px] max-w-full text-left text-5xl text-darkslateblue-200 font-urbanist mq750:gap-[16px_32px]">
              <div className="self-stretch rounded-sm box-border flex flex-row items-center justify-start py-[18.5px] px-[22px] gap-[16px] min-w-[240px] max-w-full border-[2px] border-solid border-darkslateblue-200 mq750:flex-wrap">
                <img
                  className="h-12 w-12 relative overflow-hidden shrink-0"
                  alt=""
                  src="/user-networking.svg"
                />
                <input
                  className="w-[calc(100%_-_92px)] [border:none] [outline:none] inline-block font-urbanist text-5xl bg-[transparent] h-[22px] flex-1 relative leading-[22px] font-bold text-darkslateblue-200 text-left min-w-[300px] max-w-full p-0 mq450:text-lgi mq450:leading-[18px]"
                  placeholder="Real Estate Structure"
                  type="text"
                />
              </div>
              <div className="self-stretch box-border flex flex-row items-center justify-start py-[18px] px-[22px] gap-[16px] min-w-[240px] max-w-full border-[2px] border-solid border-darkslateblue-200 mq750:flex-wrap">
                <img
                  className="h-12 w-[46px] relative overflow-hidden shrink-0"
                  alt=""
                  src="/search-engine-results-page.svg"
                />
                <b className="flex-1 relative leading-[22px] inline-block min-w-[209px] max-w-full mq450:text-lgi mq450:leading-[18px]">{`Securities & Compliance`}</b>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[32px] min-w-[335px] max-w-full text-left text-5xl text-darkslateblue-200 font-urbanist mq750:gap-[16px_32px]">
              <div className="self-stretch box-border flex flex-row flex-wrap items-center justify-start py-[19.5px] pr-[22px] pl-6 gap-[16px_14px] min-w-[240px] max-w-full border-[2px] border-solid border-darkslateblue-200">
                <img
                  className="h-12 w-12 relative overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/internet-marketing.svg"
                />
                <h3 className="m-0 flex-1 relative text-inherit leading-[22px] font-bold font-inherit inline-block min-w-[335px] max-w-full mq450:text-lgi mq450:leading-[18px]">{`Presentation & Showcase`}</h3>
              </div>
              <div className="self-stretch box-border flex flex-row items-center justify-start py-[17.5px] px-[22px] gap-[16px] min-w-[240px] max-w-full border-[2px] border-solid border-darkslateblue-200 mq750:flex-wrap">
                <img
                  className="h-12 w-12 relative overflow-hidden shrink-0"
                  alt=""
                  src="/affiliate-marketing.svg"
                />
                <input
                  className="w-[calc(100%_-_92px)] [border:none] [outline:none] inline-block font-urbanist text-5xl bg-[transparent] h-[22px] flex-1 relative leading-[22px] font-bold text-darkslateblue-200 text-left min-w-[300px] max-w-full p-0 mq450:text-lgi mq450:leading-[18px]"
                  placeholder="Marketing"
                  type="text"
                />
              </div>
            </div>
            <div className="h-[140px] w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px] whitespace-nowrap">
              <img
                className="w-32 h-8 relative overflow-hidden shrink-0"
                alt=""
              />
              <div className="self-stretch flex-1 relative leading-[22px] font-medium">
                Consider big data so that we create actionable insights.
              </div>
            </div>
            <div className="h-[140px] w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px] whitespace-nowrap">
              <img
                className="w-32 h-8 relative overflow-hidden shrink-0"
                alt=""
              />
              <div className="self-stretch flex-1 relative leading-[22px] font-medium">
                Consider big data so that we create actionable insights.
              </div>
            </div>
            <div className="h-[140px] w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px] whitespace-nowrap">
              <img
                className="w-32 h-8 relative overflow-hidden shrink-0"
                alt=""
              />
              <div className="self-stretch flex-1 relative leading-[22px] font-medium">
                Consider big data so that we create actionable insights.
              </div>
            </div>
            <div className="h-[140px] w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px] whitespace-nowrap">
              <img className="w-32 h-8 relative" alt="" />
              <div className="self-stretch flex-1 relative leading-[22px] font-medium">
                Consider big data so that we create actionable insights.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mr-[-2px] self-stretch bg-whitesmoke flex flex-col items-center justify-center py-20 px-5 box-border max-w-full z-[2] text-center text-21xl text-darkslateblue-200 font-urbanist mq750:pt-[52px] mq750:pb-[52px] mq750:box-border">
        <div className="w-[1220px] flex flex-col items-center justify-start gap-[60px] max-w-full mq750:gap-[30px_60px]">
          <h1 className="m-0 w-[806px] relative text-inherit leading-[100%] font-bold font-inherit inline-block max-w-full mq750:text-13xl mq750:leading-[32px] mq450:text-5xl mq450:leading-[24px]">
            Tokenization Process
          </h1>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-center py-0 pr-1 pl-0 gap-[32px_30.7px] text-base text-text-icons-base-second font-text-l-regular mq750:gap-[32px_15px]">
            <div className="h-[219px] flex-1 bg-base-white flex flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
              <img
                className="w-[60px] h-[60px] relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/messagesdownload.svg"
              />
              <div className="self-stretch relative leading-[22px] font-medium">
                Download App
              </div>
              <div className="flex flex-row items-center justify-center py-[3px] px-0">
                <button className="cursor-pointer [border:none] p-5 bg-darkslateblue-200 rounded-31xl flex flex-row items-center justify-center whitespace-nowrap hover:bg-steelblue">
                  <div className="relative text-base leading-[11px] font-semibold font-urbanist text-base-white text-left inline-block min-w-[106px]">
                    Download app
                  </div>
                </button>
              </div>
            </div>
            <div className="h-[219px] flex-1 bg-base-white flex flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
              <img
                className="w-[60px] h-[60px] relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/banking--financewalletalt.svg"
              />
              <div className="self-stretch relative leading-[22px] font-medium">
                Create new wallet
              </div>
              <div className="flex flex-row items-center justify-center py-[3px] px-5">
                <button className="cursor-pointer [border:none] p-5 bg-darkslateblue-200 rounded-31xl flex flex-row items-center justify-center whitespace-nowrap hover:bg-steelblue">
                  <div className="relative text-base leading-[11px] font-semibold font-urbanist text-base-white text-left">
                    Create new wallet
                  </div>
                </button>
              </div>
            </div>
            <div className="h-[219px] flex-1 bg-base-white flex flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
              <img
                className="w-[60px] h-[60px] relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/creditcardupload.svg"
              />
              <div className="self-stretch relative leading-[22px] font-medium">
                Receive tokens
              </div>
              <div className="flex flex-row items-center justify-center py-[3px] px-0">
                <button className="cursor-pointer [border:none] p-5 bg-darkslateblue-200 rounded-31xl flex flex-row items-center justify-center whitespace-nowrap hover:bg-steelblue">
                  <div className="relative text-base leading-[11px] font-semibold font-urbanist text-base-white text-left inline-block min-w-[106px]">
                    Receive tokens
                  </div>
                </button>
              </div>
            </div>
            <div className="h-[219px] flex-1 bg-base-white flex flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
              <img
                className="w-[60px] h-[60px] relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/piggybank01.svg"
              />
              <div className="self-stretch relative leading-[22px] font-medium">
                Earn interest
              </div>
              <div className="flex flex-row items-center justify-center py-[3px] px-0">
                <button className="cursor-pointer [border:none] p-5 bg-darkslateblue-200 rounded-31xl flex flex-row items-center justify-center whitespace-nowrap hover:bg-steelblue">
                  <div className="relative text-base leading-[11px] font-semibold font-urbanist text-base-white text-left inline-block min-w-[91px]">
                    Earn Interest
                  </div>
                </button>
              </div>
            </div>
            <div className="h-[140px] w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px] whitespace-nowrap text-mini">
              <img
                className="w-32 h-8 relative overflow-hidden shrink-0"
                alt=""
              />
              <div className="self-stretch flex-1 relative leading-[22px] font-medium">
                Consider big data so that we create actionable insights.
              </div>
            </div>
            <div className="h-[140px] w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px] whitespace-nowrap text-mini">
              <img
                className="w-32 h-8 relative overflow-hidden shrink-0"
                alt=""
              />
              <div className="self-stretch flex-1 relative leading-[22px] font-medium">
                Consider big data so that we create actionable insights.
              </div>
            </div>
            <div className="h-[140px] w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px] whitespace-nowrap text-mini">
              <img
                className="w-32 h-8 relative overflow-hidden shrink-0"
                alt=""
              />
              <div className="self-stretch flex-1 relative leading-[22px] font-medium">
                Consider big data so that we create actionable insights.
              </div>
            </div>
            <div className="h-[140px] w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px] whitespace-nowrap text-mini">
              <img className="w-32 h-8 relative" alt="" />
              <div className="self-stretch flex-1 relative leading-[22px] font-medium">
                Consider big data so that we create actionable insights.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mr-[-2px] self-stretch bg-base-white flex flex-col items-center justify-center py-20 px-5 box-border max-w-full z-[3] text-center text-21xl text-darkslateblue-200 font-urbanist mq450:pt-[52px] mq450:pb-[52px] mq450:box-border">
        <div className="flex flex-row items-start justify-start gap-[10px] max-w-full mq1275:flex-wrap">
          <div className="w-[557px] flex flex-col items-center justify-start py-0 px-0 box-border max-w-full mq1100:gap-[30px_60px]">
            <div className="w-[806px] flex flex-col items-center justify-start gap-[60px] max-w-[145%] shrink-0 mq1100:gap-[30px_60px]">
              <h1 className="m-0 self-stretch relative text-inherit leading-[100%] font-bold font-inherit mq750:text-13xl mq750:leading-[32px] mq450:text-5xl mq450:leading-[24px]">
                Affiliated Companies
              </h1>
              <img
                className="w-[268px] h-12 relative"
                loading="lazy"
                alt=""
                src="/logo.svg"
              />
            </div>
          </div>
          <div className="w-[557px] flex flex-col items-center justify-start py-0 px-0 box-border max-w-full mq1100:gap-[30px_60px]">
            <div className="w-[806px] flex flex-col items-center justify-start gap-[27px] max-w-[145%] shrink-0">
              <h1 className="m-0 self-stretch relative text-inherit leading-[100%] font-bold font-inherit mq750:text-13xl mq750:leading-[32px] mq450:text-5xl mq450:leading-[24px]">
                Current Clients
              </h1>
              <div
                onClick={() => {
                  window.open("https://carthage.sm8i.com", "_blank");
                }}
                style={{
                  cursor: "pointer",
                }}
                className="w-[375px] h-[133px] flex flex-col items-center justify-start max-w-full text-xl text-black"
              >
                <img
                  className="self-stretch h-[113px] relative max-w-full overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/captura-de-pantalla-20240320-a-las-31242pm-1@2x.png"
                />
                <b className="w-44 relative [text-decoration:underline] leading-[100%] inline-block mq450:text-base mq450:leading-[16px]">
                  Carthage.sm8i.com
                </b>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mr-[-2px] self-stretch h-[408px] bg-darkslateblue-200 overflow-hidden shrink-0 flex flex-row items-end justify-start pt-[129px] pb-[101px] pr-0.5 pl-[102px] box-border gap-[90px] max-w-full z-[4] text-left text-24xl text-base-white font-urbanist mq750:gap-[90px_45px] mq750:pl-[51px] mq750:box-border mq1100:pt-[84px] mq1100:pb-[66px] mq1100:box-border mq450:gap-[90px_22px] mq450:pl-5 mq450:pt-[55px] mq450:pb-[43px] mq450:box-border">
        <div className="w-[608px] flex flex-col items-start justify-start gap-[27px] max-w-[calc(100%_-_728px)] shrink-0 mq1100:max-w-full">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-2 box-border max-w-full">
            <h1 className="m-0 h-[94px] flex-1 relative text-inherit leading-[100%] font-bold font-inherit inline-block max-w-full mq750:text-15xl mq750:leading-[34px] mq450:text-7xl mq450:leading-[26px]">
              Unlocking Access To Multiple Deals On One Platform
            </h1>
          </div>
          <div className="flex flex-row items-start justify-start py-0 pr-5 pl-0">
            <div className="flex flex-col items-start justify-start pt-[1.5px] px-0 pb-0">
              <button className="cursor-pointer [border:none] py-5 px-[14.5px] bg-mediumseagreen-100 rounded-31xl flex flex-row items-start justify-start whitespace-nowrap hover:bg-mediumseagreen-200">
                <div className="relative text-xl leading-[14px] font-semibold font-urbanist text-darkslateblue-200 text-left">
                  Get Started Today
                </div>
              </button>
            </div>
            <button className="cursor-pointer [border:none] p-2.5 bg-mediumseagreen-100 h-[57px] w-[57px] rounded-31xl flex flex-row items-center justify-center box-border">
              <img
                className="h-[22px] w-[22px] relative overflow-hidden shrink-0 object-cover"
                alt=""
                src="/icon--arrow-top-right-1@2x.png"
              />
            </button>
          </div>
        </div>
        <div className="mb-[-242.4000000000001px] h-[683.9px] flex-1 flex flex-col items-start justify-start gap-[68.40000000000009px] max-w-[calc(100%_-_698px)] mq750:gap-[34px_68.4px] mq1100:hidden mq450:gap-[17px_68.4px]">
          <div className="w-[500px] flex-1 flex flex-row items-start justify-start py-0 px-[103px] box-border max-w-full mq1100:pl-[51px] mq1100:pr-[51px] mq1100:box-border mq450:pl-5 mq450:pr-5 mq450:box-border">
            <div className="self-stretch w-[658px] flex flex-col items-end justify-start gap-[60.5px] max-w-[224%] shrink-0 mq750:gap-[30px_60.5px] mq450:gap-[15px_60.5px]">
              <img
                className="w-[397px] h-[233px] relative object-contain max-w-full"
                alt=""
                src="/vector-1.svg"
              />
              <div className="self-stretch h-[89px] flex flex-row items-start justify-start max-w-full">
                <img
                  className="h-[89px] w-[444px] relative max-w-full"
                  alt=""
                  src="/vector-2.svg"
                />
              </div>
            </div>
          </div>
          <img
            className="w-[397px] h-[233px] relative object-contain max-w-full"
            alt=""
            src="/vector-3.svg"
          />
        </div>
      </section>
      <footer className="mr-[-2px] self-stretch bg-background-base-third flex flex-col items-center justify-start py-16 px-5 box-border max-w-full z-[5] text-left text-base text-darkslateblue-100 font-barlow mq450:pt-[42px] mq450:pb-[42px] mq450:box-border">
        <div className="w-[1216px] flex flex-col items-start justify-start gap-[64px] max-w-full mq750:gap-[32px_64px] mq450:gap-[16px_64px]">
          <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq1100:flex-wrap">
            <div className="h-[124px] w-[309px] flex flex-col items-start justify-start gap-[16px] text-xl text-text-icons-base-second">
              <img
                className="w-[259px] flex-1 relative max-h-full"
                alt=""
                src="/vector-109.svg"
              />
              <div className="self-stretch relative leading-[140%] mq450:text-base mq450:leading-[22px]">
                Join us on this exhilarating journey and let's capitalize
                together!
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[24px]">
              <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <b className="relative leading-[24px] inline-block min-w-[63px]">
                  Location
                </b>
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                  alt=""
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[12px] text-text-icons-base-second">
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium">
                    <p className="m-0">2015 Shepard Dr</p>
                    <p className="m-0">Houston Tx 77019</p>
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium inline-block min-w-[76px]">
                    Contact us
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="w-[52px] rounded-8xs overflow-hidden hidden flex-row items-center justify-center gap-[4px] font-text-l-regular">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium">
                    Events
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="w-[127px] relative leading-[24px] font-medium font-text-l-regular hidden">
                  Watch the Demo
                </div>
              </div>
            </div>
            <div className="h-[180px] flex flex-col items-start justify-start gap-[24px]">
              <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <b className="relative leading-[24px] inline-block min-w-[40px]">
                  Legal
                </b>
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                  alt=""
                />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[12px] text-text-icons-base-second">
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium inline-block min-w-[117px]">
                    Terms of service
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium inline-block min-w-[97px]">
                    Privacy Policy
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium inline-block min-w-[39px]">
                    Other
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="w-0 flex-1 rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="self-stretch w-0 relative leading-[24px] font-medium inline-block" />
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[24px]">
              <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <b className="relative leading-[24px] inline-block min-w-[60px]">
                  Sponsor
                </b>
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                  alt=""
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[12px] text-text-icons-base-second">
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium inline-block min-w-[91px]">
                    How it Works
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium inline-block min-w-[87px]">
                    Registration
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium inline-block min-w-[57px]">
                    Process
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium inline-block min-w-[82px]">
                    Disclosures
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[24px]">
              <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <b className="relative leading-[24px] inline-block min-w-[76px]">
                  Resources
                </b>
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                  alt=""
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[12px] text-text-icons-base-second">
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium inline-block min-w-[61px]">
                    Platform
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium inline-block min-w-[62px]">
                    About us
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium inline-block min-w-[72px]">
                    Education
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-medium">
                    Developer Orientation
                  </div>
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-center pt-8 px-5 pb-0 text-center text-text-icons-base-second font-text-l-regular border-t-[1px] border-solid border-stroke-base mq450:gap-[95px_47px]">
            <div className="w-[363px] relative leading-[24px] inline-block">
              © 2024 Smart Block Island. All Rights Reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingVersionFinal;
